import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Table } from '../../components/table';
import SEO from '../../components/seo';
import { useTranslation } from 'react-i18next';

const GENRES = gql`
{
  genres {id, name, name_en, name_ua }
}`;

const CREATE_GENRE = gql`
  mutation CreateGenre ($name: String!, $nameEn: String!, $nameUa: String!, ) {
    createGenre(input: { data: { name: $name, name_en: $nameEn, name_ua: $nameUa } }) {
      genre { name }
    }
  }
`;

const UPDATE_GENRE = gql`
  mutation UpdateGenre ($id: ID!, $name: String!, $nameEn: String!, $nameUa: String!) {
    updateGenre(input: { data: { name: $name, name_en: $nameEn, name_ua: $nameUa}, where: { id: $id } }) {
      genre { id, name, name_en, name_ua }
    }
  }
`;

const DELETE_GENRE = gql`
  mutation DeleteGenre ($id: ID!) {
    deleteGenre(input: { where: { id: $id } }) {
      genre { id, name }
    }
  }
`;

export const EditGenres = () => {
  const { t } = useTranslation();
  const { data, refetch, loading } = useQuery(GENRES);
  const [ createGenre ] = useMutation(CREATE_GENRE);
  const [ updateGenre ] = useMutation(UPDATE_GENRE);
  const [ deleteGenre ] = useMutation(DELETE_GENRE);
  const tableData = data && data.genres
    .map(({ id, name, name_en, name_ua }: {
      id: number,
      name: string,
      name_en: string,
      name_ua: string,
    }) => ({
      id,
      name,
      nameEn: name_en,
      nameUa: name_ua,
    }));
  return(
    <>
      <SEO title={t('page.admin.genres')} />
      <Table
        isLoading={loading}
        title={t('page.admin.genres')}
        addItemLabel={t('admin.add-genre')}
        columns={[
          { title: t('admin.name'), field: 'name' },
          { title: t('admin.name-en'), field: 'nameEn' },
          { title: t('admin.name-ua'), field: 'nameUa' }
        ]}
        data={tableData}
        editable={{
          onRowAdd: async ({name, nameEn, nameUa}) => {
            await createGenre({ variables: { name, nameEn, nameUa } })
            refetch();
          },
          onRowUpdate: async ({id, name, nameEn, nameUa}) => {
            await updateGenre({ variables: { id, name, nameEn, nameUa } });
            refetch()
          },
          onRowDelete: async ({id}) => {
            await deleteGenre({ variables: { id } });
            refetch()
          },
        }}
      />
    </>
  )
}
