import React from 'react';
import { navigate } from 'gatsby';
import { isBrowser } from 'utilities/gatsby'
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
} from '@material-ui/core';
import ENDPOINTS from '../../constants/endpoints';
import { LanguageSelect } from '../../components/language-select'
import {
  MusicNote,
  People,
  LocationCity,
  Explore,
  SupervisorAccount,
  InsertEmoticon,
  EventAvailable,
  Settings,
  MenuBook,
  ViewComfy
} from '@material-ui/icons';
import { isAdmin, getUser } from '../../services/auth/auth';
import { UserContext } from '../global-context';
import { LogoutButton } from 'components/logout-button';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.light,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const ALL_MENU_ITEMS = {
  EVENTS: {
    label: i18next.t('page.admin.events'),
    route: ENDPOINTS.ADMIN.EVENTS,
    icon: <EventAvailable/>
  },
  CITIES: {
    label: i18next.t('page.admin.cities'),
    route: ENDPOINTS.ADMIN.CITIES,
    icon: <LocationCity/>
  },
  LOCATIONS: {
    label: i18next.t('page.admin.locations'),
    route: ENDPOINTS.ADMIN.LOCATIONS,
    icon: <Explore/>
  },
  PERFORMERS: {
    label: i18next.t('page.admin.performers'),
    route: ENDPOINTS.ADMIN.PERFORMERS,
    icon: <InsertEmoticon/>
  },
  GENRES: {
    label: i18next.t('page.admin.genres'),
    route: ENDPOINTS.ADMIN.GENRES,
    icon: <ViewComfy/>
  },
  PERFORMANCES: {
    label: i18next.t('page.admin.performances'),
    route: ENDPOINTS.ADMIN.PERFORMANCES,
    icon: <MusicNote/>
  },
  STATISTICS: {
    label: i18next.t('page.admin.statistics'),
    route: ENDPOINTS.ADMIN.STATISTICS,
    icon: <MenuBook/>
  },
  CITY_ADMINS: {
    label: i18next.t('page.admin.city-admins'),
    route: ENDPOINTS.ADMIN.CITY_ADMINS,
    icon: <SupervisorAccount/>
  },
  SPONSORS: {
    label: i18next.t('page.sponsors'),
    route: ENDPOINTS.ADMIN.SPONSORS,
    icon: <People/>,
  },
  SETTINGS: {
    label: i18next.t('page.admin.settings'),
    route: ENDPOINTS.ADMIN.SETTINGS,
    icon: <Settings/>,
  },

};

const ADMIN_MENU_ITEMS = Object.values(ALL_MENU_ITEMS);
const MODERATOR_MENU_ITEMS = [
  ALL_MENU_ITEMS.LOCATIONS,
  ALL_MENU_ITEMS.PERFORMANCES,
];

export const AdminLayout = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const MENU_ITEMS_TO_SHOW = isAdmin()
    ? ADMIN_MENU_ITEMS
    : MODERATOR_MENU_ITEMS;

  const currentCityName = getUser().cityName;
  const context = React.useContext(UserContext);
  const activeRoute = isBrowser() && window.location.pathname;
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Typography variant='h6' noWrap className={classes.title}>
            {t('title.admin')}
            {currentCityName &&
              ` | ${currentCityName}`
            }
          </Typography>
          <LanguageSelect />
          <LogoutButton onLogout={() => {
            context && context.resetUser();
          }}/>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          elevation: 5,
        }}
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {MENU_ITEMS_TO_SHOW.map(({label, route, icon}) => (
            <ListItem selected={activeRoute === route} button key={label} onClick={() => { navigate(route) }}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
