import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Table } from '../../components/table';
import SEO from '../../components/seo';
import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { ChangeEvent } from 'components/select-field';
import { getCurrentEvent, setCurrentEvent } from './common/queries/yearly-events';

const EVENTS = gql`
{
  events {id, name, date }
}`;

const CREATE_EVENT = gql`
  mutation CreateEvent ($name: String!, $date: Date!) {
    createEvent(input: { data: { name: $name, date: $date } }) {
      event { name }
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation UpdateEvent ($id: ID!, $name: String! $date: Date) {
    updateEvent(input: { data: { name: $name, date: $date }, where: { id: $id } }) {
      event { id }
    }
  }
`;

const DELETE_EVENT = gql`
  mutation DeleteEvent ($id: ID!) {
    deleteEvent(input: { where: { id: $id } }) {
      event { id }
    }
  }
`;

const useCurrentEventData = () =>  {
  const [ currentEventData, setCurrentEventData ] = useState();
  if (!currentEventData) {
    getCurrentEvent().then(response => {
      setCurrentEventData(response);
    });
  }
  const refetch = () => setCurrentEventData(null);
  return [currentEventData && currentEventData.data.currentEvent, refetch];
}

export const EditEvents = () => {
  const { t } = useTranslation();
  const [currentEventData, refetchCurrentEvent] = useCurrentEventData();
  const { data, refetch, loading } = useQuery(EVENTS);
  const [ createEvent ] = useMutation(CREATE_EVENT);
  const [ updateEvent ] = useMutation(UPDATE_EVENT);
  const [ deleteEvent ] = useMutation(DELETE_EVENT);
  const tableData = data && data.events
    .map(({ id, name, date }: {
      id: number,
      name: string,
      date: string
    }) => ({
      id,
      name,
      date,
      isActive: (currentEventData && currentEventData.id == id)
    }));
  return(
    <>
      <SEO title={t('page.admin.events')} />
      <Table
        isLoading={loading}
        title={t('page.admin.events')}
        addItemLabel={t('admin.add-event')}
        columns={[
          { title: t('admin.name'), field: 'name' },
          { title: t('admin.date'),
            field: 'date',
            editComponent: ({value, onChange}) =>
              <input onChange={(e: ChangeEvent) => {
                onChange(e.target.value);
              }} value={value} type='date'/>
            },
          { title: t('admin.active'),
            field: 'isActive',
            editComponent: ({ value }) =>
              value  ? t('button.yes') : t('button.no')  ,
            render: ({ isActive }) =>
              isActive  ? t('button.yes') : t('button.no') 
          }
        ]}
        data={tableData}
        actions={[
          (data) => {
            return {
              icon: () => <Check/>,
              tooltip: t('admin.make-current'),
              disabled: data.isActive,
              onClick:  async (_, { name, date, id }) => {
                await setCurrentEvent(id);
                refetchCurrentEvent();
                refetch();
              }
            }
          }
        ]}
        editable={{
          onRowAdd: async ({name, date}) => {
            await createEvent({ variables: { name, date } })
            refetch();
          },
          onRowUpdate: async ({id, name, date}) => {
            await updateEvent({ variables: { id, name, date } });
            refetch()
          },
          onRowDelete: async ({id}) => {
            await deleteEvent({ variables: { id } });
            refetch()
          },
        }}
      />
    </>
  )
}
