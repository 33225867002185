import axios from 'axios';
import { getUserToken } from '../../../../services/auth/auth';

export const getCurrentEvent = () => {
    return axios.get(`${process.env.GATSBY_API_URL}/events/current`, {
      headers: {
      Authorization: `Bearer ${getUserToken()}`,
    }});
};
  
export const setCurrentEvent = (eventId: number) => {
    return axios.post(`${process.env.GATSBY_API_URL}/events/current`,
        {
            eventId
        },
        {
            headers: {
                Authorization: `Bearer ${getUserToken()}`,
            }
        }
    );
}