import React, { useEffect, useRef, useState } from 'react'
import { gql } from 'apollo-boost'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List, ListItem, ListItemAvatar, ListItemText, Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { ProgressButton } from 'components/progress-button'
import { isAdmin, getUser } from '../../services/auth/auth';
import { Table } from '../../components/table'
import { decimalHourToSexagesimal } from '../../utilities'
import { LocationPreviewModal } from '../maps/marker-location-preview';
import SEO from '../../components/seo';
import { CitySelectionWithAllCityQuerying } from './common/city-selector'
import { EventSelectionWithData } from './common/year-selector';
import { getCurrentEvent } from './common/queries/yearly-events';
import { useQuery as useQueryRest } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { getUserToken } from '../../services/auth/auth';
import { Block, MailOutline, MoodOutlined, PhoneOutlined } from '@material-ui/icons'
import { blue, grey, red } from '@material-ui/core/colors'
import { MTableToolbar } from 'material-table';


const getExportedData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/performances/export`, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    }});
};

interface Participant {
  id: string;
  name: string;
  phone: string;
  email: string;
}

const getParticipant = (id: string): Promise<AxiosResponse<Participant>> => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/users-permissions/user/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }});
};
  
interface Location {
  id: number;
  name: string;
  coordinates: string;
}

interface Performer {
  id: number;
  name: string;
  participant: {
    id: string;
  }
}
interface Performance {
  id: number;
  hourFrom: number;
  hourTo: number;
  location: Location;
  performer: Performer;
  cancelled: boolean;
  cancelReason: string;
}

const FIND_PERFORMANCES = gql`
    query performances($city: ID!, $event: ID) {
        performances (sort: "hourFrom:asc", where:  {event: $event, location: {
          city: $city
        }} ) { id, hourFrom, hourTo, location { id, name, coordinates}, performer { id, name, participant { id }}, cancelled, cancelReason }
    }
`

interface RowData {
  id: number;
  performerName: string;
  time: string;
  location: Location;
}

const useCurrentEventInformation = () => {
  const { data: currentEventData } = useQueryRest('current-event', getCurrentEvent, {
    refetchInterval: false
  });
  return currentEventData?.data.currentEvent;
}

const DataExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <div style={{
      backgroundColor: 'white',
      display: 'flex'
    }}>
      <ProgressButton
        variant='contained'
        color='primary'
        progress={isLoading}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          getExportedData()
            .then((response) => {
              const blob = new Blob(
                [response.data], {
                  type: ''
                });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = `gmd_pasirodymai_${new Date().toISOString().slice(0,10)}.xlsx`;
              link.click();
            })
            .finally(() => {
              setIsLoading(false);
            })
        }}
      >
        {t('admin.export-performances')}
      </ProgressButton>
    </div>
  )
}

const ParticipantPopover = ({
  performerName,
  participantId
}: {
  performerName: string,
  participantId: string
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setOpenState] = useState<boolean>(false);
  const [participantInfo, setParticipantInfo] = useState<{
    name: string;
    email: string;
    phone: string;
  } | null>(null);
  
  useEffect(() => {
    if (isOpen && participantId) {
      getParticipant(participantId).then(
        (response) => {
          setParticipantInfo(response.data);
        })
    }
  },[isOpen, participantId])
  return (
    <div>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenState(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          participantInfo ?
            <List>
              {
                [
                  {
                    text: participantInfo!.name,
                    icon: MoodOutlined
                  },
                  {
                    text: participantInfo!.email,
                    icon: MailOutline
                  },
                  {
                    text: participantInfo!.phone,
                    icon: PhoneOutlined
                  },
                  
                ].map(({text, icon: Icon }) => {
                  return (
                    <ListItem
                      key={text}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Icon color='secondary' />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={text}/>
                    </ListItem>
                  )
                })
              }
            </List>
            : <Box
              p={2}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <CircularProgress/>
            </Box>
        }
      </Popover>
      <Button
        style={{
          color: blue[600],
          textDecoration: 'underline'
        }}
        onClick={(event) => {
          setOpenState(true);
          setAnchorEl(event.currentTarget);
        }}>{
          performerName
        }
      </Button>
    </div>
  )
}

const getPerformances =  ({ cityId, eventId,email, limit, start }: {
  cityId: string
  eventId: string
  email: string
  limit: number
  start: number
}): Promise<AxiosResponse<Performance[]>> => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/performances?location.city.id=${cityId}&event.id=${eventId}&performer.participant.email_contains=${email}&_limit=${limit}&_start=${start}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const getPerformanceCount =  ({ cityId, eventId,email, limit, start }: {
  cityId: string
  eventId: string
  email: string
  limit: number
  start: number
}): Promise<AxiosResponse<Performance[]>> => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/performances/count?location.city.id=${cityId}&event.id=${eventId}&performer.participant.email_contains=${email}&_limit=${limit}&_start=${start}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const cancelPerformance =  (performanceId: number, cancelReason: string) => {
  const { token } = getUser();
  return axios.post(`${process.env.GATSBY_API_URL}/performances/${performanceId}/cancel`,
    {
      performanceId,
      cancelReason
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}


const CancelPerformanceConfirmation = (props: {
  performerName?: string,
  isOpen: boolean;
  onConfirm: (reason: string) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { performerName, isOpen, onConfirm, onClose } = props;
  const [ cancelReason, setCancelReason ] = useState('');
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onEscapeKeyDown={onClose}
    >
      <DialogContent>
        <DialogTitle>
          {t('admin.performance-cancelation-confirmation', {
            performerName
          })}
        </DialogTitle>
        <DialogContentText>
          {t('admin.performance-cancelation-explanation')}
        </DialogContentText>
        <TextField
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          onChange={(e) => {
            setCancelReason(e.target.value);
          }}
        >
          {cancelReason}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="primary"
        >
          {t('button.no')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={cancelReason === ''}
          onClick={() => {
            onConfirm(cancelReason);
            onClose();
          }}
        >
          {t('button.cancel-performance')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const EditPerformances = (props: {}) => {
  const currentEventData = useCurrentEventInformation();
  const { cityId: userCity } = getUser();
  const { t } = useTranslation();
  const [ state, setState ] = useState({
    currentCity: userCity,
    selectedEvent: undefined,
    pendingCancelationPerformanceId: undefined, // meant to store the id of the event to cancel in a modal
    tableData: undefined
  });
  useEffect(() => {
    if (!state.selectedEvent && currentEventData) {
      setState({
        ...state,
        selectedEvent: currentEventData.id
      })
    }
  }, [currentEventData, state])
  const { currentCity, selectedEvent, pendingCancelationPerformanceId, tableData } = state;
  const tableRef = useRef(); // Ref is used to trigger data refetch programatically

  return(
    <Grid container direction='column' spacing={2}>
      <SEO title={t('page.admin.performances')} />
      <Grid item container spacing={2}>
        {
          isAdmin() && 
            <Grid item>
              <CitySelectionWithAllCityQuerying
                selectedCity={currentCity}
                onSelectCity={(cityId: number) => {
                  setState({
                    ...state,
                    currentCity: cityId
                  });
                  tableRef.current && tableRef.current.onQueryChange();
                }}
              />
            </Grid>
        }
        {
          currentCity &&
            <Grid item>
              <EventSelectionWithData
                selectedEvent={selectedEvent}
                onSelectEvent={(eventId) => {
                  setState({
                    ...state,
                    selectedEvent: eventId
                  });
                  tableRef.current && tableRef.current.onQueryChange();
                }}
              />
            </Grid>
        }
        {
          isAdmin() &&
          currentCity &&
            <Grid item>
              <DataExport/>
            </Grid>
        }
      </Grid>
      {currentCity && <Grid item>
        <Table
          title={t('page.admin.performances')}
          tableRef={tableRef}
          data={async ({search, page, pageSize}) => {
            if (selectedEvent && (currentCity || userCity)) {
              const {data} = await getPerformances({
                cityId: currentCity || userCity,
                eventId: selectedEvent,
                email: search,
                limit: pageSize,
                start: page * pageSize
              })

              const {data: performancesCount} = await getPerformanceCount({
                cityId: currentCity || userCity,
                eventId: selectedEvent,
                email: search,
                limit: pageSize,
                start: page * pageSize
              })

              const tableData = data.map(({
                id,
                performer,
                location,
                hourFrom,
                hourTo,
                cancelled,
                cancelReason
              }) => ({
                id,
                performerName: performer.name,
                participantId: performer.participant,
                time: `${decimalHourToSexagesimal(hourFrom)}-${decimalHourToSexagesimal(hourTo)}`,
                location,
                cancelled,
                cancelReason
              }))
              setState({
                ...state,
                tableData
              })
              return {
                data: tableData,
                totalCount: performancesCount,
                page
              }
            }
          }}
          components={{
            Toolbar: (props) => {
              return <MTableToolbar {...props} localization={{
                searchPlaceholder: t('admin.search-by-email'),
                searchTooltip: t('admin.search-by-email')
              }}/>
            }
          }}
          options={{
            grouping: true,
            defaultExpanded: true,
            pageSizeOptions: [10, 20, 30],
            pageSize: 10,
            debounceInterval: 400
          }}
          actions={[
            ({ cancelled}) =>  ({
              icon: () => <Block style={{ color: cancelled ? grey[500]: red[500] }} />,
              disabled: cancelled,
              tooltip: cancelled ? t('admin.performance-cancelled') : t('admin.cancel-performance'),
              onClick:  async (_, { name, date, id }) => {
                setState({
                  ...state,
                  pendingCancelationPerformanceId: id
                })
              }
            }) 
          ]}
          columns={[
            {
              title: t('admin.performer'),
              field: 'performerName',
              render: ({performerName, participantId}) => {
                return (
                  <ParticipantPopover
                    performerName={performerName}
                    participantId={participantId}
                  />
                )
              }
            },
            {
              title: t('admin.location'),
              field: 'location.id',
              defaultGroupOrder: 0,
              render: (rowData, type) => {
                if (type === 'group') {
                // MUI Table provides only the field value when rendering a group.
                // To get location data we search for it externally. More discussion:
                // https://github.com/mbrn/material-table/issues/1577
                  const performance = tableData && tableData.find(({location}) => location && location.id === rowData);
                  const location = performance && performance.location;
                  return (
                    location &&
                    <LocationPreviewModal
                      name={location.name}
                      coordinates={location.coordinates}
                    /> || '-'
                  )
                }
                const { location } = rowData;
                return location && <LocationPreviewModal
                  name={location.name}
                  coordinates={location.coordinates}
                /> || '-'
              }
            },
            {
              title: t('admin.time'),
              field: 'time',
            },
            {
              title: t('admin.performance-cancel-reason'),
              field: 'cancelReason',
              render: ({ cancelReason }) => {
                return cancelReason || '-'
              }
            }
          ]}
        />
      </Grid>}
      <CancelPerformanceConfirmation
        performerName={tableData?.find(({id}) => id === pendingCancelationPerformanceId)?.performerName}
        onConfirm={async (cancelReason: string) => {
          if (pendingCancelationPerformanceId) {
            await cancelPerformance(pendingCancelationPerformanceId, cancelReason);
            tableRef.current.onQueryChange()
          }
        }}
        onClose={() => {
          setState({
            ...state,
            pendingCancelationPerformanceId: undefined
          })
        }}
        isOpen={!!pendingCancelationPerformanceId}/>
    </Grid>
  )
}
