import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import ApolloClient from 'apollo-boost';
import { navigate } from 'gatsby'
import { ApolloProvider } from '@apollo/react-hooks'
import { isEmpty, path, isNil } from 'ramda'

import { AdminLayout } from '../components/layouts/admin-layout'
import { PrivateRoute } from '../components/private-route'
import { EditSponsors } from '../components/admin/edit-sponsors'
import { EditCities } from '../components/admin/edit-cities';
import { EditLocations } from '../components/admin/edit-locations';
import { EditPerformances } from '../components/admin/edit-performances';
import { ViewStatistics } from '../components/admin/view-statistics';
import { EditPerformers } from '../components/admin/edit-performers';
import { EditCityAdmins } from '../components/admin/edit-city-admins';
import { EditEvents } from '../components/admin/edit-events';
import { EditGenres } from '../components/admin/edit-genres';
import { EditSettings } from '../components/admin/edit-settings';
import endpoints from '../constants/endpoints';
import { isParticipant } from '../services/auth/auth'
import { UserContext } from '../components/global-context';
import SEO from '../components/seo'

const AdminPage = () => {
  const context = React.useContext(UserContext);
  useEffect(() => {
    const userData = path(['user'], context)
    const isLoggedIn = !isNil(userData) && !isEmpty(userData)

    if (!isLoggedIn || isParticipant()) {
      navigate(endpoints.LOGIN)
    }
  }, [context])
  
  return (
    <ApolloProvider client={new ApolloClient({
      uri: `${process.env.GATSBY_API_URL}/graphql`,
      headers: {
        Authorization: `Bearer ${context.user && context.user.token}`,
      },
    })}>
      <SEO title='Admin' />
      <AdminLayout>
        <Router>
          <PrivateRoute adminOnly path={endpoints.ADMIN.EVENTS} component={EditEvents}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.CITIES} component={EditCities}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.CITY_ADMINS} component={EditCityAdmins}/>
          <PrivateRoute path={endpoints.ADMIN.LOCATIONS} component={EditLocations}/>
          <PrivateRoute path={endpoints.ADMIN.PERFORMANCES} component={EditPerformances}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.PERFORMERS} component={EditPerformers}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.GENRES} component={EditGenres}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.SPONSORS} component={EditSponsors}/>
          <PrivateRoute adminOnly path={endpoints.ADMIN.STATISTICS} component={ViewStatistics}/> 
          <PrivateRoute adminOnly path={endpoints.ADMIN.SETTINGS} component={EditSettings}/>
        </Router>
      </AdminLayout>
    </ApolloProvider>
  )
}

export default AdminPage;
