import React, { useState, useRef  } from 'react';
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@material-ui/core'
import { Check, Clear } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { green, red } from '@material-ui/core/colors';
import { Table } from '../../components/table';
import SEO from '../../components/seo';
import { getUser } from '../../services/auth/auth';



interface SearchQuery {
  limit: number;
  start: number;
  search: string;
  status: VerificationStatus
}

const getPerformerCount = ({limit, start, search, status}: SearchQuery): Promise<AxiosResponse<number>> => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/performers/count?_limit=${limit}&_start=${start}&name_contains=${search}&participant.verification_status=${status}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }});
};

const getPerformers = ({limit, start, search, status}: SearchQuery): Promise<AxiosResponse<Performer[]>> => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/performers/all?_limit=${limit}&_start=${start}&name_contains=${search}&participant.verification_status=${status}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }});
};


enum VerificationStatus {
    NEW = 'new',
    CONFIRMED = 'confirmed',
    DECLINED = 'declined',
}

interface Performer {
    id: number;
    name: string;
    genre: {
      name: string;
      name_en: string;
      id: string;
    };
    membersCount: number;
    website: string;
    about: string;
    participant: {
        id: any,
        verification_status: VerificationStatus
        email: string;
        phone?: string;
    }
    image: {
        url: string;
    }
}

const confirmUser =  (userId: number) => {
  const { token } = getUser();
  return axios.post(`${process.env.GATSBY_API_URL}/users-permissions/user/confirm`,
    {
      userId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const declinedUser =  (userId: number) => {
  const { token } = getUser();
  return axios.post(`${process.env.GATSBY_API_URL}/users-permissions/user/decline`,
    {
      userId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const LogoViewComponent = ({ imageUrl }: {
  imageUrl: string;
}) => {
  return (
    <>
      {
        imageUrl &&
          <img style={{width: '50px'}} src={`${process.env.GATSBY_API_URL}${imageUrl}`}/>
      }
    </>
  )
}

export const StatusFilter = (props: {
    onSelectStatus: (status: VerificationStatus) => void;
    selectedStatus: VerificationStatus;
}) => {
  const { t } = useTranslation();
  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{t('admin.status')}</FormLabel>
      <RadioGroup value={props.selectedStatus} onChange={(e) =>{
        props.onSelectStatus(e.target.value as VerificationStatus)
      }}>
        {
          [
            {label: t('admin.new'), value: 'new'},
            {label: t('admin.confirmed'), value: 'confirmed'},
            {label: t('admin.declined'), value: 'declined'}
          ].map(({value, label}) => {
            return <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
          })
        }
      </RadioGroup>
    </FormControl>)
}


export const EditPerformers = (props: {}) => {
  const { t, i18n } = useTranslation();
  const tableRef = useRef(); // Ref is used to trigger data refetch programatically
  const [state, setState] = useState<{
      status: VerificationStatus
    }>({
      status: VerificationStatus.NEW
    });
  return(
    <Grid container direction='column' spacing={2}>
      <SEO title={t('page.admin.performers')} />
      <Grid item>
        <StatusFilter
          selectedStatus={state.status}
          onSelectStatus={(status: VerificationStatus) => {
            setState({
              status
            });
            //@ts-ignore
            tableRef.current.onQueryChange();
          }}
        />
      </Grid>
      <Grid item>
        <Table
          title={t('page.admin.performers')}
          tableRef={tableRef}
          data={async ({search, pageSize, page}: {
            search: string;
            pageSize: number;
            page: number;
          }) => {
            const {data: performers} = await getPerformers({
              search,
              limit: pageSize,
              start: page * pageSize,
              status: state.status
            });
            const { data: performerCount } = await getPerformerCount({
              search,
              limit: pageSize,
              start: page * pageSize,
              status: state.status
            });
            return {
              totalCount: performerCount,
              page,
              data: performers.map(({
                id,
                name,
                genre,
                website,
                about,
                image,
                membersCount,
                participant
              }) => ({
                id,
                name,
                genre: !genre ? '-' : i18n.language === 'lt-LT'
                  ? genre.name
                  : i18n.language === 'en-US'
                    ? genre.name_en : genre.name_ua,
                website,
                email: participant.email,
                phone: participant.phone,
                about,
                imageUrl: image && image.url,
                membersCount,
                verificationStatus: participant.verification_status,
                participantId: participant.id
              })),
            }
          }}
          options={{
            defaultExpanded: true,
            grouping: true,
            debounceInterval: 400,
            pageSizeOptions: [10, 20, 30],
            pageSize: 10
          }}
          actions={[
            (data) => {
              return ({
                icon: () =>
                  (data.verificationStatus === VerificationStatus.DECLINED || data.verificationStatus === VerificationStatus.NEW)
                    ? <Check style={{ color: green[500] }}/>
                    : <Check/>,
                tooltip: t('admin.confirm-performance'),
                disabled: data.verificationStatus === VerificationStatus.CONFIRMED,
                onClick:  async (_, { participantId }) => {
                  await confirmUser(participantId);
                  //@ts-ignore
                  tableRef.current.onQueryChange();
                }
              })
            },
            (data) => ({
              icon: () =>
                data.verificationStatus === VerificationStatus.DECLINED
                  ? <Clear/>
                  : <Clear style={{ color: red[500] }}/>,
              tooltip: t('admin.decline-performance'),
              disabled: data.verificationStatus === VerificationStatus.DECLINED,
              onClick: async (_, { participantId }) => {
                await declinedUser(participantId);
                //@ts-ignore
                tableRef.current.onQueryChange();
              }
            })
          ]}
          columns={[
            {
              title: t('admin.performer'),
              field: 'name',
            },
            {
              title: t('admin.genre'),
              field: 'genre',
            },
            {
              title: t('admin.member-count'),
              field: 'membersCount',
            },
            {
              title: t('admin.profile'),
              field: 'email',
              defaultGroupOrder: 0,

            },
            {
              title: t('participate.phone'),
              field: 'phone',
            },

            {
              title: t('participate.website'),
              field: 'website',
            },
            {
              title: t('participate.about'),
              field: 'about',
              render: ({about}) => {
                return <div style={{maxWidth: '200px'}}>{about}</div>
              }
            },
            {
              title: t('admin.logo'),
              field: 'image',
              render: LogoViewComponent
            },
            {
              title: t('admin.status'),
              field: 'verificationStatus',
              render: (data: any) => {
                const translations = {
                  [VerificationStatus.NEW]: t('admin.new'),
                  [VerificationStatus.CONFIRMED]: t('admin.confirmed'),
                  [VerificationStatus.DECLINED]: t('admin.declined'),
                };
                return translations[data.verificationStatus];
              }
            }

          ]}
        />
      </Grid>
    </Grid>
  )
}
