import React from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { getUserToken } from 'services/auth/auth'
import { SwitchField } from 'components/switch-field'
import SEO from '../../components/seo';

const getRegistrationStatus = () => {
    return axios.get(`${process.env.GATSBY_API_URL}/global-settings/registration-status`)
};

const setRegistrationStatus = ({isRegistrationOpen}: {
    isRegistrationOpen: boolean;
}) => {
    return axios.post(`${process.env.GATSBY_API_URL}/global-settings/registration-status`,
        {
            isRegistrationOpen
        },
        {
            headers: {
                Authorization: `Bearer ${getUserToken()}`,
            }
        }
    )
}

const RegistrationToggle = () => {
  const { t } = useTranslation();
  const { data, refetch, isFetching } = useQuery('isRegistrationOpen', getRegistrationStatus);
  const isRegistrationOpen = data && data.data.isRegistrationOpen;
  const handleChange = async () => {
    await setRegistrationStatus({isRegistrationOpen: !isRegistrationOpen});
    refetch();
  }
  const getLabel = () => isRegistrationOpen
    ? t('admin.registration-active')
    : t('admin.registration-inactive')

  return (
    <>
      <Typography variant='h4'>
        {t('admin.registration')}
      </Typography>
      <SwitchField
        name='resitration'
        label={getLabel()}
        value={isRegistrationOpen}
        onChange={handleChange}
        disabled={isFetching}
      />
    </>
  )
}

export const EditSettings = () => {
  const { t } = useTranslation();
  return(
    <>
      <SEO title={t('page.admin.settings')} />
      <RegistrationToggle/>
    </>
  )
}
