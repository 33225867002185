import React, { ChangeEvent } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core'

type EventId = any;

interface Event {
  id: EventId;
  name: string;
}

interface EventSelectionProps {
  events?: Event[];
  selectedEvent?: EventId;
  onSelectEvent: (id: EventId) => void;
}
  
  const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    }
}));

const FIND_EVENTS = gql`
  query {
      events
      {  id, name }
}
`

export const useQueryEvents = () => {
  return useQuery<{
    events: Event[]
  }>(FIND_EVENTS);
}

export const EventSelection = (props: EventSelectionProps) => {
    const { events, selectedEvent, onSelectEvent } = props;
    const { t } = useTranslation();
    const classes = useStyles(props);
    return (
      <FormControl variant='filled' className={classes.formControl}>
        <InputLabel variant='filled' htmlFor='event'>{t('page.admin.events')}</InputLabel>
        <Select
          value={selectedEvent || ''}
          variant='filled'
          onChange={(e: ChangeEvent<{ name?: string, value: unknown }>) => {
            onSelectEvent(parseInt(e.target.value as string, 10) as EventId)
          }}
          inputProps={{
            id: 'event',
          }}
         >
           <MenuItem value='' />
            {
              events && events.map(({ id, name }) =>
                <MenuItem key={id} value={id}>{name}</MenuItem>)
            }
        </Select>
    </FormControl>
    )
}

export const EventSelectionWithData = (props: Pick<EventSelectionProps, 'onSelectEvent' | 'selectedEvent'>) => {
  const { data } = useQueryEvents();
  return <EventSelection {...props} events={data && data.events}/>
}
