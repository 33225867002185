import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography
} from '@material-ui/core';
import { Explore } from '@material-ui/icons';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { LatLngTuple, LeafletMouseEvent } from 'leaflet';

interface MarkerLocationPreviewProps {
    // we store and pass values in the format of 55.2997256,23.9055102
    coordinates?: string;
    center?: string;
    onClick?: (e: LeafletMouseEvent) => void;
}
  
export const MarkerLocationPreview = ({ center, coordinates, onClick }: MarkerLocationPreviewProps) => {
    let markerLatLngTuple
    let initialCenterLatLngTuple
    if (coordinates) {
      const markerLat = coordinates && parseFloat(coordinates.split(',')[0]);
      const markerLng = coordinates && parseFloat(coordinates.split(',')[1]);
      markerLatLngTuple = [markerLat, markerLng] as LatLngTuple;
    }

    if (center) {
      const centerLat = parseFloat(center.split(',')[0]);
      const centerLng = parseFloat(center.split(',')[1]);
      initialCenterLatLngTuple = [centerLat, centerLng] as LatLngTuple;
    }

    const mapCenter = markerLatLngTuple ? markerLatLngTuple : initialCenterLatLngTuple;
    
    return (
      <Map
        style={{
          height: '400px',
          width: '500px',
        }}
        zoom={markerLatLngTuple ? 13 : 6 /** assuming if there's no marker, we're zoomed out at city level */}
        center={mapCenter}
        onClick={onClick}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markerLatLngTuple && <Marker
          position={markerLatLngTuple}
        />}
      </Map>
    )
  }
  
interface LocationPreviewModalProps {
  name: string;
  coordinates: string;
}
  
export const LocationPreviewModal = ({ coordinates, name }: LocationPreviewModalProps) => {
  const { t } = useTranslation();
  const [ isMapOpen, setModalOpenState ] = useState(false);
  const onClose = setModalOpenState.bind(null, false);
  const onOpen = setModalOpenState.bind(null, true);
  return (
    <>
      <Button
        size='small'
        color='primary'
        variant='contained'
        onClick={onOpen}
        endIcon={<Explore color='secondary'/>}
      >
        <Typography>{name}</Typography>
      </Button>
      <Dialog
        open={isMapOpen}
        onClose={onClose}
      >
        <DialogTitle>{t('Location')}</DialogTitle>
        <MarkerLocationPreview
          coordinates={coordinates}
        />
        <DialogActions>
            <Button onClick={onClose} color='secondary'>
              { t('button.close') }
            </Button>
        </DialogActions>
      </Dialog>
    </>
    )
}
